/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Confirm from 'vux/src/components/confirm';
import TransferDom from 'vux/src/directives/transfer-dom';
import Draggable from 'vuedraggable'; //TODO: 看看以后能不能去掉

export default {
  name: 'AllPrograms',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Draggable: Draggable,
    Confirm: Confirm
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (this.showFlag) {
      next(false);
      this.showToast = true; // Dialog.confirm({
      //     title: '操作提示',
      //     message: '确认要放弃编辑吗？',
      // })
      //     .then(() => {
      //         this.showEdit = true
      //         this.showFlag = false
      //         this.getMyMenu()
      //     })
      //     .catch(() => {
      //         // on cancel
      //     })
    } else {
      next();
    }
  },
  data: function data() {
    return {
      myMenu: [],
      //我的应用
      allMenu: [],
      //全部应用
      options: {
        disabled: true,
        //定义是否此sortable对象是否可用，为true时sortable对象不能拖放排序等功能，为false时为可以进行排序，相当于一个开关；
        animation: 150,
        //单位：ms，定义排序动画的时间；
        scroll: true //默认为true，当排序的容器是个可滚动的区域，拖放可以引起区域滚动

      },
      showEdit: true,
      //是否可编辑
      showFlag: false,
      //添加/删除图标显示隐藏
      menuIds: '',
      showToast: false,
      show: false,
      username: 'gh_91ec3b79d5a8',
      // gh_ 开头的原始小程序ID
      path: '/pages/index/index.html?dt_openid=',
      // 一定要以 .html 结尾
      txt: '内部超市',
      bwidth: '',
      bheight: '',
      fontSize: ''
    };
  },
  watch: {},
  computed: {},
  methods: {
    /**
     * @function点击编辑按钮
     *      wzx
     *   2019.03.16
     **/
    myEdit: function myEdit() {
      this.showEdit = false;
      this.showFlag = true;
      this.options.disabled = false;
    },

    /**
     * @function点击保存按钮--保存已编辑的应用
     *      wzx
     *   2019.03.16
     **/
    mySave: function mySave() {
      var _this = this;

      var menuIds = new Set();

      var _iterator = _createForOfIteratorHelper(this.myMenu),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          menuIds.add(item.menuId);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.menuIds = Array.from(menuIds).join(',');
      this.$utils.Tools.loadShow();
      this.$api.Home.saveMyMenu({
        menuIds: this.menuIds,
        userId: this.$utils.Store.getItem('userSerial'),
        companyId: '0'
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.$utils.Tools.toastShow(msg);

          _this.showEdit = true;
          _this.showFlag = false;
          _this.options.disabled = true;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /**
     * @function点击所有应用中的某个应用添加/删除到我的应用里
     *      wzx
     *   2019.03.16
     **/
    operateMenu: function operateMenu(obj, index) {
      if (obj.sign) {
        if (this.myMenu.length < 11) {
          this.myMenu.push(obj);

          for (var i = 0; i < this.allMenu.length; i++) {
            if (obj.menuId == this.allMenu[i].menuId) {
              this.$set(this.allMenu[i], 'sign', 0);
            }
          }
        } else {
          this.$utils.Tools.toastShow('首页最多添加11个应用');
        }
      } else {
        this.$set(this.allMenu[index], 'sign', 1);

        for (var j = 0; j < this.myMenu.length; j++) {
          if (obj.menuId == this.myMenu[j].menuId) {
            this.myMenu.splice(j, 1);
          }
        }
      }
    },

    /**
     * @function删除我的应用
     *      wzx
     *   2019.03.16
     **/
    deleteMenu: function deleteMenu(obj, index) {
      if (this.myMenu.length > 1) {
        this.myMenu.splice(index, 1);
      } else {
        this.$utils.Tools.toastShow('首页最少要保留1个应用');
      }

      for (var i = 0; i < this.allMenu.length; i++) {
        if (obj.menuId == this.allMenu[i].menuId) {
          this.$set(this.allMenu[i], 'sign', 1);
        }
      }
    },

    /**
     * @function获取我的应用
     *      wzx
     *   2019.03.16
     **/
    getMyMenu: function getMyMenu() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getHomeMenu({
        companyId: 0,
        userId: this.$utils.Store.getItem('userSerial'),
        spareCol1: 1,
        spareCol2: 0
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.myMenu = data;

          _this2.myMenu.forEach(function (item, index) {
            // 循环"我的应用"列表,添加sign属性，值为0，代表可移除
            _this2.$set(_this2.myMenu[index], 'sign', 0);
          });

          _this2.$utils.Tools.loadHide(); //获取全部的应用


          _this2.getAllMenu();
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },

    /**
     * @function获取所有应用
     *      wzx
     *   2019.03.16
     **/
    getAllMenu: function getAllMenu() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Home.getAllMenu({
        companyId: 0,
        useFlag: 1,
        userSerial: this.$utils.Store.getItem('userSerial'),
        spareCol1: 1,
        spareCol2: 0,
        userId: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.allMenu = data;

          _this3.allMenu.forEach(function (item, index) {
            // 将全部应用列表中的应用sign置为1，即可添加
            _this3.$set(_this3.allMenu[index], 'sign', 1);
          }); // 当"我的应用"列表和"全部应用"列表均不为空


          if (_this3.myMenu.length > 0 && _this3.allMenu.length > 0) {
            //循环全部应用列表
            for (var i = 0; i < _this3.allMenu.length; i++) {
              //循环我的应用列表
              for (var j = 0; j < _this3.myMenu.length; j++) {
                //全部应用列表中的某个应用在我的应用列表中找到相同的应用（id相同）
                if (_this3.allMenu[i].menuId == _this3.myMenu[j].menuId) {
                  // 将其sign置为0，即可移除
                  _this3.$set(_this3.allMenu[i], 'sign', 0);
                }
              }
            }
          }

          _this3.$utils.Tools.loadHide();
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /**
     * @function放弃编辑的应用
     *      wzx
     *   2019.03.16
     **/
    onConfirm: function onConfirm() {
      this.showEdit = true;
      this.showFlag = false; // this.getMyMenu()
    },
    onCancel: function onCancel() {},
    onShow: function onShow() {},
    onHide: function onHide() {},
    choose: function choose(evt) {
      var ITEM_HEIGHT = evt.item.offsetHeight;
      var container = evt.path[1];
      var index = evt.oldIndex;

      if (index * ITEM_HEIGHT < container.scrollTop) {
        container.scrollTo({
          top: index * ITEM_HEIGHT
        });
      } else if ((index + 1) * ITEM_HEIGHT > container.scrollTop + container.offsetHeight) {
        container.scrollTo({
          top: (index + 1) * ITEM_HEIGHT - container.offsetHeight
        });
      }
    },

    /**
     * @function点击我的应用中某项
     *      wzx
     *   2019.03.27
     **/
    myMenuClick: function myMenuClick(value) {
      if (!this.showFlag) {
        this.$router.push(value);
      }
    },

    /**
     * @function点击所有应用中某项
     *      wzx
     *   2019.03.27
     **/
    allMenuClick: function allMenuClick(value) {
      if (!this.showFlag) {
        this.$router.push(value);
      }
    }
  },
  created: function created() {
    var _this4 = this;

    this.getMyMenu();
    this.getAllMenu();
    var openId = window.vm.$utils.Store.getItem('openId');
    this.path = '/pages/index/index.html?dt_openid=' + openId;
    var localUrl = window.location.href.split('#')[0];
    this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
      var code = response.code,
          data = response.data;

      if (+code !== _this4.$code.success) {// this.$utils.Tools.toastShow(msg);
      } else {
        _this4.$utils.WeiXin.wxConfig(data);
      }
    }).catch(function () {//  this.$utils.Tools.httpError();
    });
  },
  mounted: function mounted() {}
};